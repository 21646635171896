
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { add } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonSpinner, IonList, IonListHeader, IonItem, IonLabel, IonIcon,
        IonButtons, IonButton, IonBackButton, IonBadge, IonThumbnail, IonText, } from '@ionic/vue';

// API services
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'OrderListPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonSpinner, IonList, IonListHeader, IonItem, IonLabel, IonIcon,
                IonButtons, IonButton, IonBackButton, IonBadge, IonThumbnail, IonText, },
  setup() {
    // methods or filters
    const { t } = useI18n();
    const store = useStore();
    const { getOrderStatusColor, formatDate } = utils();

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingAppUserData);
    const orders = computed(() => store.state.userOrders);

    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // icons
      add,

      // variables
      loading, orders,

      // methods
      formatDate, getOrderStatusColor,
      getMerchantName: (merchantId) => {
        const merchant = store.getters.getMerchantById(merchantId);
        return merchant?.name;
      }
    }
  }
}
